<template>
    <svg
        v-if="!en"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="242.092"
        height="44.753"
        viewBox="0 0 566.9 170.1"
        fill="#fff"
    >
        <g id="Logo">
            <g>
                <path
                    d="M97.3,69.5c2.1-2.5,2.1-6.2,2.1-6.2c-0.1-5.5-4.6-10-10.2-10l-3.8,0c0,0,0,0,0,0s0,0,0,0c-1.4,0-2.6,1.2-2.6,2.6
			c0,1.4,1.2,2.6,2.6,2.6c0,0,0,0,0,0s0,0,0,0h2c3.9,0,5.8,1.5,5.9,4.8c-0.1,3.3-2,4.8-6,4.8h-5.2c-0.4,0-0.8,0-1.2,0.2
			c-0.8,0.2-1.6,0.8-1.6,2v0.2c0,0.7,0.3,1.3,1,2.1c0,0,11.8,13.4,13.4,15.1c0.5,0.7,1.3,1.1,2.3,1.1c1.6,0,2.9-1.3,2.9-2.9
			c0-0.8-0.3-1.5-0.8-2l0,0l-8.8-9.8c-0.5-0.6-1.6-1.4-1.6-1.4l3.1-0.2C90.9,72.7,94.8,72.5,97.3,69.5z"
                />
                <path
                    d="M107.5,66.1c1.7,0,3-1.3,3-3v-4.6h9.4c1.4,0,2.6-1.2,2.6-2.6c0-1.4-1.2-2.6-2.6-2.6h-12.8c-1.4,0-2.6,1.2-2.6,2.6v7.2
			C104.5,64.8,105.9,66.1,107.5,66.1z"
                />
                <path
                    d="M74.4,53.3H59c-1.4,0-2.6,1.2-2.6,2.6v5.9c0,1.7,1.3,3,3,3s3-1.3,3-3v-3.3h12.1c1.4,0,2.6-1.2,2.6-2.6
			C77,54.5,75.9,53.3,74.4,53.3z"
                />
                <path
                    d="M120,68.2h-5.2c-5.6,0-10.2,4.6-10.2,10.2v7.5v0c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3h0V78c0-2.6,2.1-4.6,4.7-4.6l4.8,0
			c1.4,0,2.6-1.2,2.6-2.6C122.6,69.3,121.4,68.2,120,68.2z"
                />
                <path
                    d="M74.5,83.3C74.5,83.3,74.4,83.3,74.5,83.3h-7.4c-2.6,0-4.7-2.1-4.7-4.6v-5.3h9.5c1.4,0,2.6-1.2,2.6-2.6
			c0-1.4-1.2-2.6-2.6-2.6H59.4c-1.7,0-3,1.3-3,3v7.2c0,5.6,4.6,10.2,10.2,10.2h7.9c1.4,0,2.6-1.2,2.6-2.6
			C77,84.5,75.9,83.3,74.5,83.3z"
                />
                <path
                    d="M193.7,49h2.9c2.2,0,3.5-1.3,3.5-3.5v-2.7c0-2.2-1.3-3.5-3.5-3.5h-2.9c-2.2,0-3.5,1.3-3.5,3.5v2.7
			C190.2,47.7,191.4,49,193.7,49z"
                />
                <path
                    d="M245.2,67.2c0-8.9-5.9-14.6-14.8-14.6c-3.9,0-6.9,1-9,3.1V42.2c0-2.1-1.3-3.4-3.4-3.4h-1.5c-2.1,0-3.4,1.3-3.4,3.4v43.2
			c0,2.2,1.5,3.8,3.6,3.8h0.3c2.2,0,3.6-1.5,3.6-3.7c2.2,2.5,5.5,3.8,9.7,3.8c9,0,14.9-5.7,14.9-14.6L245.2,67.2L245.2,67.2z
			 M236.6,75.4c0,4.6-2.7,7.2-7.6,7.2s-7.6-2.6-7.6-7.2v-9c0-4.6,2.7-7.2,7.6-7.2s7.6,2.6,7.6,7.2V75.4z"
                />
                <path
                    d="M444.5,55.4c0-1.3-0.8-2.1-2.3-2.1h-2.8c-1.6,0-2.4,0.6-3.1,2.8l-8.1,25.1L420,56.1c-0.7-2.2-1.5-2.8-3.1-2.8h-3.3
			c-1.5,0-2.3,0.8-2.3,2.1c0,0.5,0.1,1.1,0.4,1.7l9.7,28.3c0.8,2.4,1.8,3.4,4.3,3.4h4.3c2.6,0,3.5-0.9,4.3-3.4l9.7-28.3
			C444.3,56.5,444.5,55.9,444.5,55.4z"
                />
                <path
                    d="M505.6,53h-0.8c-3.5,0-6.2,1.7-8,4.8l-4-4c-1.3-1.3-3-1.5-4.3-0.1l-1.4,1.4c-1.3,1.3-1.2,3,0.1,4.3l3.1,3.2v23.5
			c0,1.5,1,2.4,2.5,2.4h3.5c1.5,0,2.5-1,2.5-2.4V68.4c0-4.9,2.3-7.3,6.9-7.3h1c2.4,0,3.9-1.3,3.9-3.5v-0.7
			C510.5,54.4,508.8,53,505.6,53z"
                />
                <path
                    d="M326.2,89.3c9.2,0,14.6-4.1,14.6-11.2v-0.8c0-5.7-2.6-8.6-9.2-9.4l-9-1.1c-2.2-0.3-3.3-1.1-3.3-3.2v-0.7c0-2.7,2-4,6.6-4
			c4.5,0,6.5,1.2,6.5,3.7v0.3c0,0.8,0.5,1.2,1.3,1.2h5c0.8,0,1.3-0.6,1.3-1.4v-0.3c0-6.5-4.8-9.8-14.1-9.8
			c-9.2,0-14.4,4.1-14.4,11.2v0.6c0,5.8,2.7,8.7,9.3,9.5l9,1.1c2.2,0.3,3.1,1.1,3.1,3.2v0.8c0,2.7-2.3,4.1-6.8,4.1
			c-4.9,0-7.1-1.2-7.1-3.8v-0.4c0-0.8-0.4-1.2-1.2-1.2h-5c-0.8,0-1.4,0.5-1.4,1.3v0.5C311.5,86,316.4,89.3,326.2,89.3z"
                />
                <path
                    d="M304,79.2c0-0.7-0.4-1.1-1.1-1.1h-5.7c-0.8,0-1.1,0.3-1.1,1.1v0.2c0,2.4-2.1,3.6-6.2,3.6c-5.5,0-7.9-1.9-7.9-6.3v-2.7
			h19.7c2,0,2.9-1,2.9-2.9v-5c0-8.8-5.2-13.4-15.3-13.4c-10.1,0-15.6,4.9-15.6,13.9v9.3c0,8.9,5.5,13.5,16.2,13.5
			c9.5,0,14.3-3.3,14.3-9.8L304,79.2L304,79.2z M281.9,65.1c0-4.1,2.5-6.3,7.2-6.3s7.4,2.2,7.4,6.3v2.6h-14.6V65.1z"
                />
                <path
                    d="M263.8,88.5h3.4c1.3,0,2-0.8,2-2.1v-2.9c0-1.2-0.8-1.9-1.9-1.9h-2.8c-1.5,0-2.1-0.6-2.1-2.4v-37c0-2.1-1.3-3.4-3.4-3.4
			h-1.5c-2.1,0-3.4,1.3-3.4,3.4v37.5C254,85.9,256.3,88.5,263.8,88.5z"
                />
                <path
                    d="M378.1,79.5v-0.3c0-0.7-0.4-1.1-1.1-1.1h-5.7c-0.8,0-1.1,0.3-1.1,1.1v0.2c0,2.4-2.1,3.6-6.2,3.6c-5.5,0-7.9-1.9-7.9-6.3
			v-2.7h19.7c2,0,2.9-1,2.9-2.9v-5c0-8.8-5.2-13.4-15.3-13.4c-10.1,0-15.6,4.9-15.6,13.9v9.3c0,8.9,5.5,13.5,16.2,13.5
			C373.3,89.3,378.1,86,378.1,79.5z M356,65.1c0-4.1,2.5-6.3,7.2-6.3s7.4,2.2,7.4,6.3v2.6H356V65.1z"
                />
                <path
                    d="M187.4,60.1h4.3v19.6c0,6.2,2.3,8.8,9.8,8.8h3.4c1.3,0,2-0.8,2-2.1v-2.9c0-1.2-0.8-1.9-1.9-1.9h-2.8
			c-1.5,0-2.1-0.6-2.1-2.4v-23c0-2.1-1-2.9-2.9-2.9h-9.7c-1.1,0-1.9,0.7-1.9,1.8v3.2C185.5,59.4,186.2,60.1,187.4,60.1z"
                />
                <path
                    d="M397,86.1V68.4c0-4.9,2.3-7.3,6.9-7.3h1c2.4,0,3.9-1.3,3.9-3.5v-0.7c0-2.5-1.7-3.8-4.9-3.8H403c-3.5,0-6.2,1.8-8,4.8l-4-4
			c-1.3-1.3-3-1.5-4.3-0.1l-1.4,1.4c-1.3,1.3-1.2,3,0.1,4.3l3.1,3.2v23.5c0,1.5,1,2.4,2.5,2.4h3.5C396,88.5,397,87.6,397,86.1z"
                />
                <path
                    d="M180.1,73.3c0-4.8-2.4-8.2-7-9.9c4.1-1.8,6.2-5,6.2-9.5v-2c0-8.5-5.2-12.5-16.2-12.5h-15c-2.4,0-3.9,1.5-3.9,3.8v41.3
			c0,2.4,1.5,3.8,3.9,3.8h15.6c11,0,16.4-4.3,16.4-13L180.1,73.3L180.1,73.3z M152.9,46.5h10.3c5.2,0,7.5,1.8,7.5,6v2
			c0,4.1-2.2,5.9-7.5,5.9h-10.3V46.5z M171.5,75.4c0,4.3-2.4,6.2-7.7,6.2h-10.9V67.1h10.9c5.3,0,7.7,1.9,7.7,6.2V75.4z"
                />
                <path
                    d="M465,52.6c-10.1,0-15.6,4.9-15.6,13.9v9.3c0,8.9,5.5,13.5,16.2,13.5c9.5,0,14.3-3.3,14.3-9.8v-0.3c0-0.7-0.4-1.1-1.1-1.1
			H473c-0.8,0-1.1,0.3-1.1,1.1v0.2c0,2.4-2.1,3.6-6.2,3.6c-5.5,0-7.9-1.9-7.9-6.3v-2.7h19.7c2,0,2.9-1,2.9-2.9v-5
			C480.3,57.1,475.1,52.6,465,52.6z M472.3,67.7h-14.6v-2.6c0-4.1,2.5-6.3,7.2-6.3s7.4,2.2,7.4,6.3V67.7z"
                />
                <path
                    d="M187.9,115.4h-3v-2.9c0-0.8-0.5-1.3-1.3-1.3h-1c-0.8,0-1.3,0.5-1.3,1.3v2.9h-1.9c-0.5,0-0.8,0.3-0.8,0.8v1.3
			c0,0.5,0.3,0.8,0.8,0.8h1.9v7.8c0,3.2,1.3,4.4,4.8,4.4h1.8c0.5,0,0.9-0.3,0.9-0.9v-1.3c0-0.5-0.3-0.8-0.8-0.8h-1.6
			c-1,0-1.4-0.4-1.4-1.4v-7.8h3c0.5,0,0.8-0.3,0.8-0.8v-1.3C188.7,115.7,188.4,115.4,187.9,115.4z"
                />
                <path d="M321.7,109.4c-1,0-1.5,0.5-1.5,1.5v1.1c0,1,0.5,1.5,1.5,1.5h1.2c1,0,1.5-0.5,1.5-1.5v-1.1c0-1-0.5-1.5-1.5-1.5H321.7z" />
                <path
                    d="M156.9,109.5H146c-1,0-1.6,0.6-1.6,1.7v17.7c0,1,0.6,1.6,1.6,1.6h10.9c0.7,0,1-0.4,1-1v-1c0-0.7-0.4-1-1-1h-8.8v-6.1h6.9
			c0.9,0,1.6-0.5,1.6-1.5v-0.1c0-0.9-0.7-1.5-1.6-1.5h-6.9v-5.7h8.8c0.7,0,1-0.4,1-1v-1C157.9,109.9,157.6,109.5,156.9,109.5z"
                />
                <path
                    d="M169.4,115.1c-2,0-3.5,0.7-4.5,2.1l-1.5-1.5c-0.6-0.6-1.3-0.6-1.9-0.1l-0.6,0.6c-0.6,0.6-0.5,1.3,0.1,1.9l1.4,1.4v10.1
			c0,0.6,0.4,1.1,1.1,1.1h1.5c0.6,0,1.1-0.4,1.1-1.1v-8.3c0-2.1,1.1-3.2,3-3.2s3,1.1,3,3.2v8.3c0,0.6,0.4,1.1,1.1,1.1h1.5
			c0.6,0,1.1-0.4,1.1-1.1v-8.3C175.6,117.3,173.1,115.1,169.4,115.1z"
                />
                <path
                    d="M326.6,127.6h-1.2c-0.7,0-0.9-0.3-0.9-1v-9.8c0-0.9-0.4-1.3-1.3-1.3H319c-0.5,0-0.8,0.3-0.8,0.8v1.3
			c0,0.5,0.3,0.8,0.8,0.8h1.8v8.4c0,2.7,1,3.8,4.2,3.8h1.4c0.5,0,0.9-0.3,0.9-0.9v-1.3C327.4,127.9,327,127.6,326.6,127.6z"
                />
                <path
                    d="M338.2,115.1c-2,0-3.5,0.7-4.5,2.1l-1.5-1.5c-0.6-0.6-1.3-0.6-1.9-0.1l-0.6,0.6c-0.6,0.6-0.5,1.3,0.1,1.9l1.4,1.4v10.1
			c0,0.6,0.4,1.1,1,1.1h1.5c0.6,0,1-0.4,1-1.1v-8.3c0-2.1,1-3.2,3-3.2s3,1.1,3,3.2v8.3c0,0.6,0.4,1.1,1,1.1h1.5c0.6,0,1-0.4,1-1.1
			v-8.3C344.3,117.3,341.9,115.1,338.2,115.1z"
                />
                <path
                    d="M205.2,126.5v-15.9c0-0.9-0.6-1.5-1.5-1.5H203c-0.9,0-1.5,0.6-1.5,1.5v5.7c-0.9-0.8-2.2-1.3-3.8-1.3
			c-3.8,0-6.3,2.5-6.3,6.3v3.2c0,3.8,2.6,6.3,6.4,6.3c2.2,0,3.8-0.7,4.7-2.1l1.5,1.5c0.6,0.6,1.3,0.6,1.9,0.1l0.6-0.6
			c0.6-0.6,0.5-1.3-0.1-1.9L205.2,126.5z M201.6,124.9c0,2-1.1,3.1-3.2,3.1s-3.2-1.1-3.2-3.1v-3.9c0-2,1.1-3.1,3.2-3.1
			s3.2,1.1,3.2,3.1V124.9z"
                />
                <path
                    d="M427.7,115.1c-4.4,0-6.7,2.1-6.7,6v4c0,3.8,2.4,5.8,6.9,5.8c4,0,6.1-1.4,6.1-4.2v-0.1c0-0.3-0.2-0.5-0.5-0.5h-2.5
			c-0.4,0-0.5,0.1-0.5,0.4v0.1c0,1-0.9,1.5-2.7,1.5c-2.4,0-3.4-0.8-3.4-2.7v-1.2h8.5c0.8,0,1.2-0.4,1.2-1.3v-2.2
			C434.3,117.1,432.1,115.1,427.7,115.1z M430.9,121.6h-6.2v-1.1c0-1.8,1-2.7,3.1-2.7s3.1,0.9,3.1,2.7V121.6z"
                />
                <path
                    d="M355.2,115.1c-4.4,0-6.7,2.1-6.7,6v4c0,3.8,2.4,5.8,6.9,5.8c4,0,6.1-1.4,6.1-4.2v-0.1c0-0.3-0.2-0.5-0.5-0.5h-2.5
			c-0.4,0-0.5,0.1-0.5,0.4v0.1c0,1-0.9,1.5-2.7,1.5c-2.4,0-3.4-0.8-3.4-2.7v-1.2h8.5c0.8,0,1.2-0.4,1.2-1.3v-2.2
			C361.8,117.1,359.6,115.1,355.2,115.1z M358.4,121.6h-6.2v-1.1c0-1.8,1-2.7,3.1-2.7s3.1,0.9,3.1,2.7V121.6z"
                />
                <path
                    d="M309.2,115.1c-4.4,0-6.7,2.1-6.7,6v4c0,3.8,2.4,5.8,6.9,5.8c4,0,6.1-1.4,6.1-4.2v-0.1c0-0.3-0.2-0.5-0.5-0.5h-2.5
			c-0.4,0-0.5,0.1-0.5,0.4v0.1c0,1-0.9,1.5-2.7,1.5c-2.4,0-3.4-0.8-3.4-2.7v-1.2h8.5c0.8,0,1.2-0.4,1.2-1.3v-2.2
			C315.8,117.1,313.5,115.1,309.2,115.1z M312.3,121.6h-6.2v-1.1c0-1.8,1-2.7,3.1-2.7s3.1,0.9,3.1,2.7V121.6z"
                />
                <path
                    d="M385.2,119.8c1.7-0.8,2.6-2.1,2.6-4.1v-0.8c0-3.6-2.2-5.3-6.9-5.3h-6.4c-1,0-1.6,0.6-1.6,1.7v17.7c0,1,0.6,1.6,1.6,1.6
			h6.7c4.7,0,7-1.8,7-5.6V124C388.2,122,387.2,120.5,385.2,119.8z M376.5,112.5h4.4c2.2,0,3.2,0.8,3.2,2.6v0.8c0,1.8-1,2.5-3.2,2.5
			h-4.4V112.5z M384.5,124.9c0,1.8-1,2.6-3.3,2.6h-4.7v-6.2h4.7c2.3,0,3.3,0.8,3.3,2.6V124.9z"
                />
                <path
                    d="M399.4,127.6h-1.2c-0.7,0-0.9-0.3-0.9-1v-9.8c0-0.9-0.4-1.3-1.3-1.3h-4.2c-0.5,0-0.8,0.3-0.8,0.8v1.3
			c0,0.5,0.3,0.8,0.8,0.8h1.8v8.4c0,2.7,1,3.8,4.2,3.8h1.4c0.5,0,0.9-0.3,0.9-0.9v-1.3C400.2,127.9,399.9,127.6,399.4,127.6z"
                />
                <path
                    d="M395.8,109.4h-1.2c-1,0-1.5,0.5-1.5,1.5v1.1c0,1,0.5,1.5,1.5,1.5h1.2c1,0,1.5-0.5,1.5-1.5v-1.1
			C397.3,110,396.8,109.4,395.8,109.4z"
                />
                <path
                    d="M410.9,115.1c-1.6,0-2.9,0.4-3.8,1.3v-5.8c0-0.9-0.6-1.5-1.5-1.5h-0.7c-0.9,0-1.5,0.6-1.5,1.5v18.5c0,1,0.6,1.6,1.6,1.6
			h0.1c0.9,0,1.5-0.6,1.6-1.6c0.9,1.1,2.3,1.6,4.2,1.6c3.8,0,6.4-2.5,6.4-6.3v-3.2C417.3,117.6,414.7,115.1,410.9,115.1z
			 M413.6,124.9c0,2-1.1,3.1-3.3,3.1c-2.1,0-3.3-1.1-3.3-3.1v-3.9c0-2,1.1-3.1,3.3-3.1c2.1,0,3.3,1.1,3.3,3.1V124.9z"
                />
                <path
                    d="M246.7,109.2h-0.7c-0.9,0-1.5,0.6-1.5,1.5v18.8c0,0.6,0.4,1.1,1.1,1.1h1.5c0.6,0,1.1-0.4,1.1-1.1v-18.8
			C248.2,109.8,247.6,109.2,246.7,109.2z"
                />
                <path
                    d="M233.8,115.1c-4.3,0-6.7,2.2-6.7,6.1v3.6c0,3.8,2.4,6,6.7,6c4.1,0,6.4-1.6,6.4-5v-0.5c0-0.4-0.3-0.7-0.7-0.7h-2.1
			c-0.4,0-0.7,0.3-0.7,0.7v0.4c0,1.6-0.8,2.3-2.9,2.3c-2.1,0-3-1-3-3V121c0-2,0.9-3.1,3-3.1c2.1,0,2.9,0.8,2.9,2.3v0.3
			c0,0.4,0.3,0.7,0.7,0.7h2.1c0.4,0,0.7-0.3,0.7-0.7v-0.4C240.2,116.8,237.8,115.1,233.8,115.1z"
                />
                <path
                    d="M297.6,126.5v-15.9c0-0.9-0.6-1.5-1.5-1.5h-0.7c-0.9,0-1.5,0.6-1.5,1.5v5.7c-0.9-0.8-2.2-1.3-3.8-1.3
			c-3.8,0-6.3,2.5-6.3,6.3v3.2c0,3.8,2.5,6.3,6.4,6.3c2.2,0,3.8-0.7,4.7-2.1l1.5,1.5c0.6,0.6,1.3,0.6,1.9,0.1l0.6-0.6
			c0.6-0.6,0.5-1.3-0.1-1.9L297.6,126.5z M294,124.9c0,2-1.1,3.1-3.2,3.1s-3.2-1.1-3.2-3.1v-3.9c0-2,1.1-3.1,3.2-3.1
			s3.2,1.1,3.2,3.1V124.9z"
                />
                <path
                    d="M216.8,115.1c-4.4,0-6.7,2.1-6.7,6v4c0,3.8,2.4,5.8,6.9,5.8c4.1,0,6.1-1.4,6.1-4.2v-0.1c0-0.3-0.2-0.5-0.5-0.5h-2.5
			c-0.4,0-0.5,0.1-0.5,0.4v0.1c0,1-0.9,1.5-2.7,1.5c-2.4,0-3.4-0.8-3.4-2.7v-1.2h8.5c0.8,0,1.2-0.4,1.2-1.3v-2.2
			C223.3,117.1,221.1,115.1,216.8,115.1z M219.9,121.6h-6.2v-1.1c0-1.8,1.1-2.7,3.1-2.7s3.1,0.9,3.1,2.7L219.9,121.6L219.9,121.6z"
                />
                <path
                    d="M267.3,115.1c-4.4,0-6.7,2.1-6.7,6v4c0,3.8,2.4,5.8,6.9,5.8c4,0,6.1-1.4,6.1-4.2v-0.1c0-0.3-0.2-0.5-0.5-0.5h-2.5
			c-0.4,0-0.5,0.1-0.5,0.4v0.1c0,1-0.9,1.5-2.7,1.5c-2.4,0-3.4-0.8-3.4-2.7v-1.2h8.5c0.8,0,1.2-0.4,1.2-1.3v-2.2
			C273.8,117.1,271.6,115.1,267.3,115.1z M270.4,121.6h-6.2v-1.1c0-1.8,1-2.7,3.1-2.7s3.1,0.9,3.1,2.7V121.6z"
                />
                <path
                    d="M253.1,122.7l4.1-5.2c0.5-0.6,0.6-0.9,0.6-1.2c0-0.5-0.3-0.8-1-0.8h-1.3c-0.8,0-1.1,0.2-1.6,0.9l-3.8,5
			c-0.4,0.6-0.6,1.1-0.6,1.6c0,0.5,0.1,1,0.6,1.6l4.4,5.6c0.7,0.8,1.8,1,2.6,0.4c0.8-0.6,1-1.7,0.3-2.6L253.1,122.7z"
                />
                <path
                    d="M444.4,127.6h-1.2c-0.7,0-0.9-0.3-0.9-1v-15.9c0-0.9-0.6-1.5-1.5-1.5h-0.7c-0.9,0-1.5,0.6-1.5,1.5v16.1
			c0,2.7,1,3.8,4.2,3.8h1.4c0.5,0,0.9-0.3,0.9-0.9v-1.3C445.2,127.9,444.9,127.6,444.4,127.6z"
                />
            </g>
        </g>
        <g id="Schutzzone">
        </g>
    </svg>
    <svg
        v-else
        class="logo__en"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 454.1 98.19"
        width="242.092"
        height="44.753"
        fill="#fff"
    >
        <path
            class="cls-1"
            d="M89.84,79a10.73,10.73,0,0,0,2.07-6.19,10.21,10.21,0,0,0-10.22-10H77.91a2.6,2.6,0,1,0,0,5.19H80c3.94,0,5.79,1.49,5.93,4.81-.14,3.33-2,4.82-6,4.82H74.76a4.58,4.58,0,0,0-1.22.15,1.93,1.93,0,0,0-1.55,2V80a3.24,3.24,0,0,0,1,2.11L86.34,97.2a2.87,2.87,0,0,0,2.28,1.11,2.9,2.9,0,0,0,2.12-4.88h0L82,83.64a13.12,13.12,0,0,0-1.61-1.36l3.09-.15A9.51,9.51,0,0,0,89.84,79Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M112.54,62.81H99.7a2.59,2.59,0,0,0-2.6,2.59v6.49a3,3,0,1,0,6,0V68h9.45a2.6,2.6,0,0,0,0-5.19Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M67,62.81H51.52a2.6,2.6,0,0,0-2.6,2.59v5.19a3,3,0,0,0,6,0V68H67a2.6,2.6,0,1,0,0-5.19Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M112.54,77.63h-5.21A10.22,10.22,0,0,0,97.1,87.81v7.51a3,3,0,0,0,6,0h0V87.45a4.67,4.67,0,0,1,4.68-4.65l4.76,0a2.6,2.6,0,0,0,0-5.19Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M67,92.81H59.62a4.67,4.67,0,0,1-4.69-4.64V82.82h9.48a2.6,2.6,0,0,0,0-5.19H51.92a3,3,0,0,0-3,3v7.19A10.22,10.22,0,0,0,59.15,98H67a2.6,2.6,0,0,0,0-5.19Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M434.71,62.79h-2.8c-1.61,0-2.38.56-3.08,2.8l-8.12,25.06-8.12-25.06c-.7-2.24-1.47-2.8-3.08-2.8h-3.29a2,2,0,0,0-2.31,2.1,5.54,5.54,0,0,0,.35,1.68L414,94.85c.84,2.45,1.75,3.36,4.34,3.36h4.27c2.59,0,3.5-.91,4.34-3.36l9.73-28.28a5.54,5.54,0,0,0,.35-1.68A2,2,0,0,0,434.71,62.79Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M457.53,62c-10.15,0-15.61,4.9-15.61,13.93v9.31c0,8.89,5.53,13.51,16.17,13.51,9.45,0,14.28-3.29,14.28-9.8v-.28a1,1,0,0,0-1.12-1.12h-5.74c-.84,0-1.12.28-1.12,1.05v.21c0,2.38-2.1,3.57-6.23,3.57-5.53,0-7.91-1.89-7.91-6.3V83.37H470c2,0,2.87-1,2.87-2.94v-5C472.86,66.57,467.61,62,457.53,62Zm7.28,15.12H450.25V74.55c0-4.13,2.45-6.3,7.21-6.3s7.35,2.17,7.35,6.3Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M333.35,87.57V86.8c0-5.74-2.59-8.61-9.17-9.45l-9-1.12c-2.24-.28-3.29-1-3.29-3.22v-.7c0-2.73,2-4,6.65-4S325,69.51,325,72v.35a1.12,1.12,0,0,0,1.26,1.19h5a1.31,1.31,0,0,0,1.33-1.4v-.35c0-6.51-4.76-9.8-14.14-9.8s-14.42,4.06-14.42,11.2v.56c0,5.81,2.66,8.68,9.31,9.52l9,1.12c2.17.28,3.15,1,3.15,3.15v.84c0,2.66-2.31,4.06-6.79,4.06-4.9,0-7.07-1.19-7.07-3.78v-.42a1.07,1.07,0,0,0-1.19-1.19h-5a1.27,1.27,0,0,0-1.4,1.33v.49q0,9.87,14.7,9.87C328,98.77,333.35,94.64,333.35,87.57Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M237.73,84.14V76.65c0-8.89-5.95-14.63-14.84-14.63-3.85,0-6.86,1-9,3.08V51.66a3.2,3.2,0,0,0-3.43-3.43H209a3.2,3.2,0,0,0-3.43,3.43V94.85a3.53,3.53,0,0,0,3.64,3.78h.28a3.51,3.51,0,0,0,3.64-3.71c2.17,2.52,5.46,3.85,9.73,3.85C231.78,98.77,237.73,93,237.73,84.14Zm-23.8.77v-9c0-4.62,2.66-7.21,7.63-7.21s7.63,2.59,7.63,7.21v9c0,4.62-2.66,7.21-7.63,7.21S213.93,89.53,213.93,84.91Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M140.71,98h15.61c11,0,16.38-4.27,16.38-13V82.81c0-4.83-2.38-8.19-7-9.94a9.59,9.59,0,0,0,6.16-9.45v-2C171.86,53,166.68,49,155.69,49h-15a3.57,3.57,0,0,0-3.85,3.85v41.3A3.57,3.57,0,0,0,140.71,98Zm4.76-42.07h10.29c5.25,0,7.49,1.82,7.49,6v2c0,4.13-2.24,5.88-7.49,5.88H145.47Zm0,20.65h10.92c5.32,0,7.7,1.89,7.7,6.16v2.17c0,4.27-2.38,6.16-7.7,6.16H145.47Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M498.13,62.51h-.84a8.85,8.85,0,0,0-8,4.83l-4-4a2.86,2.86,0,0,0-4.34-.14l-1.4,1.4a2.86,2.86,0,0,0,.14,4.34l3.15,3.15V95.55A2.31,2.31,0,0,0,485.32,98h3.5a2.31,2.31,0,0,0,2.45-2.45V77.84c0-4.9,2.31-7.35,6.86-7.35h1c2.38,0,3.92-1.33,3.92-3.5v-.7C503,63.84,501.35,62.51,498.13,62.51Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M199.37,95.9V93a1.76,1.76,0,0,0-1.89-1.89h-2.8c-1.54,0-2.1-.63-2.1-2.38v-23c0-2.1-1-2.94-2.94-2.94h-9.73A1.75,1.75,0,0,0,178,64.61v3.15a1.75,1.75,0,0,0,1.89,1.82h4.27v19.6c0,6.23,2.31,8.82,9.8,8.82h3.36A1.9,1.9,0,0,0,199.37,95.9Z"
            transform="translate(-48.92 -48.23)"
        /><rect
            class="cls-1"
            x="133.78"
            y="0.56"
            width="9.87"
            height="9.66"
            rx="3.5"
        /><path
            class="cls-1"
            d="M296.6,89v-.28a1,1,0,0,0-1.12-1.12h-5.74c-.84,0-1.12.28-1.12,1.05v.21c0,2.38-2.1,3.57-6.23,3.57-5.53,0-7.91-1.89-7.91-6.3V83.37h19.74c2,0,2.87-1,2.87-2.94v-5c0-8.82-5.25-13.37-15.33-13.37S266.15,66.92,266.15,76v9.31c0,8.89,5.53,13.51,16.17,13.51C291.77,98.77,296.6,95.48,296.6,89ZM274.48,74.55c0-4.13,2.45-6.3,7.21-6.3s7.35,2.17,7.35,6.3v2.59H274.48Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M259.71,98a1.9,1.9,0,0,0,2-2.1V93a1.76,1.76,0,0,0-1.89-1.89h-2.8c-1.54,0-2.1-.63-2.1-2.38v-37a3.2,3.2,0,0,0-3.43-3.43H250a3.2,3.2,0,0,0-3.43,3.43V89.18c0,6.23,2.31,8.82,9.8,8.82Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M355.82,62c-10.15,0-15.61,4.9-15.61,13.93v9.31c0,8.89,5.53,13.51,16.17,13.51,9.45,0,14.28-3.29,14.28-9.8v-.28a1,1,0,0,0-1.12-1.12H363.8c-.84,0-1.12.28-1.12,1.05v.21c0,2.38-2.1,3.57-6.23,3.57-5.53,0-7.91-1.89-7.91-6.3V83.37h19.74c2,0,2.87-1,2.87-2.94v-5C371.15,66.57,365.9,62,355.82,62Zm7.28,15.12H348.54V74.55c0-4.13,2.45-6.3,7.21-6.3s7.35,2.17,7.35,6.3Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M396.42,62.51h-.84a8.85,8.85,0,0,0-8,4.83l-4-4a2.86,2.86,0,0,0-4.34-.14l-1.4,1.4A2.86,2.86,0,0,0,378,69l3.15,3.15V95.55A2.31,2.31,0,0,0,383.61,98h3.5a2.31,2.31,0,0,0,2.45-2.45V77.84c0-4.9,2.31-7.35,6.86-7.35h1c2.38,0,3.92-1.33,3.92-3.5v-.7C401.32,63.84,399.64,62.51,396.42,62.51Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M149.49,119.09H138.63a1.53,1.53,0,0,0-1.65,1.64v17.7a1.53,1.53,0,0,0,1.65,1.65h10.86a.92.92,0,0,0,1-1v-1a.91.91,0,0,0-1-1h-8.82V130.9h6.9a1.43,1.43,0,0,0,1.59-1.46v-.16a1.43,1.43,0,0,0-1.59-1.46h-6.9v-5.67h8.82a.92.92,0,0,0,1-1v-1A.92.92,0,0,0,149.49,119.09Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M162.15,132.46,166,127a1.92,1.92,0,0,0,.48-1.12c0-.56-.33-.87-1-.87h-1.32c-.78,0-1.11.28-1.68,1.12l-2.64,4-2.64-4c-.57-.84-.9-1.12-1.68-1.12h-1.38c-.63,0-1,.31-1,.87a1.92,1.92,0,0,0,.48,1.12l3.84,5.48-4,5.65a1.83,1.83,0,0,0-.48,1.1c0,.57.33.87,1,.87h1.32c.78,0,1.11-.26,1.68-1.11l2.76-4.19,2.76,4.19c.57.85.9,1.11,1.68,1.11h1.38c.63,0,1-.3,1-.87a1.83,1.83,0,0,0-.48-1.1Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M177.84,124.67a5.28,5.28,0,0,0-4.68,2.1l-1.53-1.54a1.23,1.23,0,0,0-1.86,0l-.6.6a1.23,1.23,0,0,0,.06,1.86l1.35,1.34v16a1.37,1.37,0,0,0,1.47,1.47h.66a1.37,1.37,0,0,0,1.47-1.47v-5.82a5.39,5.39,0,0,0,3.78,1.29,5.91,5.91,0,0,0,6.3-6.26v-3.21C184.26,127.13,181.71,124.67,177.84,124.67Zm2.76,9.81c0,2-1.11,3.09-3.21,3.09s-3.21-1.12-3.21-3.09v-3.87c0-2,1.11-3.09,3.21-3.09s3.21,1.1,3.21,3.09Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M194.34,137.11h-1.2c-.66,0-.9-.27-.9-1V120.23a1.37,1.37,0,0,0-1.47-1.47h-.66a1.37,1.37,0,0,0-1.47,1.47V136.3c0,2.67,1,3.78,4.2,3.78h1.44a.81.81,0,0,0,.87-.9v-1.26A.76.76,0,0,0,194.34,137.11Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M204.57,124.67c-4.44,0-6.93,2.19-6.93,6.15v3.45c0,3.95,2.49,6.14,7,6.14s6.93-2.19,6.93-6.14v-3.45C211.53,126.86,209,124.67,204.57,124.67Zm3.3,10c0,1.92-1.14,2.95-3.27,2.95s-3.3-1-3.3-2.95v-4.17c0-1.92,1.14-2.93,3.27-2.93s3.3,1,3.3,2.93Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M223,124.88h-.36a3.8,3.8,0,0,0-3.42,2.06l-1.71-1.71a1.23,1.23,0,0,0-1.86,0l-.6.6a1.23,1.23,0,0,0,.06,1.86l1.35,1.34V139a1,1,0,0,0,1.05,1.05H219A1,1,0,0,0,220,139v-7.59c0-2.09,1-3.14,2.94-3.14h.42a1.49,1.49,0,0,0,1.68-1.5v-.31C225.06,125.44,224.34,124.88,223,124.88Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M233.94,124.67c-4.35,0-6.69,2.1-6.69,6v4c0,3.81,2.37,5.79,6.93,5.79,4,0,6.12-1.41,6.12-4.2v-.12a.44.44,0,0,0-.48-.48h-2.46c-.36,0-.48.12-.48.46v.08c0,1-.9,1.53-2.67,1.53-2.37,0-3.39-.81-3.39-2.7v-1.16h8.46c.84,0,1.23-.42,1.23-1.26V130.4C240.51,126.61,238.26,124.67,233.94,124.67Zm3.12,6.48h-6.24V130c0-1.76,1.05-2.69,3.09-2.69s3.15.93,3.15,2.69Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M262.86,125h-1.2c-.69,0-1,.24-1.32,1.2l-3.45,11.28-3.51-11.28c-.3-1-.63-1.2-1.32-1.2h-1.41a.87.87,0,0,0-1,.91,2.26,2.26,0,0,0,.15.71L253.92,139a1.31,1.31,0,0,0,1.47,1.11h.54l-1.47,4a1.56,1.56,0,0,0,1,2.19,1.66,1.66,0,0,0,2.16-1l6.09-18.69a2.26,2.26,0,0,0,.15-.71A.87.87,0,0,0,262.86,125Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M273.48,124.67c-4.44,0-6.93,2.19-6.93,6.15v3.45c0,3.95,2.49,6.14,7,6.14s6.93-2.19,6.93-6.14v-3.45C280.44,126.86,278,124.67,273.48,124.67Zm3.3,10c0,1.92-1.14,2.95-3.27,2.95s-3.3-1-3.3-2.95v-4.17c0-1.92,1.14-2.93,3.27-2.93s3.3,1,3.3,2.93Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M297.87,136.09v-10a1,1,0,0,0-1.05-1.06h-1.5a1,1,0,0,0-1.05,1.06v8.27c0,2.08-1,3.15-3,3.15s-3-1.11-3-3.15v-8.27a1,1,0,0,0-1.05-1.06h-1.5a1,1,0,0,0-1.05,1.06v8.27c0,3.9,2.46,6.09,6.18,6.09a5.08,5.08,0,0,0,4.47-2.09l1.53,1.52a1.23,1.23,0,0,0,1.86.06l.6-.6a1.23,1.23,0,0,0-.06-1.86Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M310.41,124.88h-.36a3.8,3.8,0,0,0-3.42,2.06l-1.71-1.71a1.23,1.23,0,0,0-1.86,0l-.6.6a1.23,1.23,0,0,0,.06,1.86l1.35,1.34V139a1,1,0,0,0,1.05,1.05h1.5a1,1,0,0,0,1.05-1.05v-7.59c0-2.09,1-3.14,2.94-3.14h.42a1.49,1.49,0,0,0,1.68-1.5v-.31C312.51,125.44,311.79,124.88,310.41,124.88Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M334.77,129.32a4.11,4.11,0,0,0,2.64-4v-.84c0-3.63-2.22-5.34-6.93-5.34h-6.42a1.53,1.53,0,0,0-1.65,1.64v17.7a1.53,1.53,0,0,0,1.65,1.65h6.69c4.71,0,7-1.83,7-5.58v-.93A4.24,4.24,0,0,0,334.77,129.32Zm-8.67-7.26h4.41c2.25,0,3.21.78,3.21,2.58v.84c0,1.76-1,2.51-3.21,2.51H326.1Zm8,12.42c0,1.82-1,2.63-3.3,2.63H326.1V130.9h4.68c2.28,0,3.3.81,3.3,2.64Z"
            transform="translate(-48.92 -48.23)"
        /><rect
            class="cls-1"
            x="293.74"
            y="70.77"
            width="4.23"
            height="4.14"
            rx="1.5"
        /><path
            class="cls-1"
            d="M349,137.11h-1.2c-.66,0-.9-.27-.9-1v-9.83c0-.9-.42-1.27-1.26-1.27h-4.17a.76.76,0,0,0-.81.79v1.35a.74.74,0,0,0,.81.77h1.83v8.4c0,2.67,1,3.78,4.2,3.78h1.44a.81.81,0,0,0,.87-.9v-1.26A.76.76,0,0,0,349,137.11Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M360.48,124.67a5.32,5.32,0,0,0-3.84,1.31v-5.75a1.37,1.37,0,0,0-1.47-1.47h-.66a1.37,1.37,0,0,0-1.47,1.47v18.5a1.51,1.51,0,0,0,1.56,1.62h.12a1.5,1.5,0,0,0,1.56-1.59,5.25,5.25,0,0,0,4.17,1.65c3.84,0,6.39-2.46,6.39-6.26v-3.21C366.84,127.13,364.29,124.67,360.48,124.67Zm2.7,9.81c0,2-1.14,3.09-3.27,3.09s-3.27-1.12-3.27-3.09v-3.87c0-2,1.14-3.09,3.27-3.09s3.27,1.1,3.27,3.09Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M376.92,137.11h-1.2c-.66,0-.9-.27-.9-1V120.23a1.37,1.37,0,0,0-1.47-1.47h-.66a1.37,1.37,0,0,0-1.47,1.47V136.3c0,2.67,1,3.78,4.2,3.78h1.44a.81.81,0,0,0,.87-.9v-1.26A.76.76,0,0,0,376.92,137.11Z"
            transform="translate(-48.92 -48.23)"
        /><path
            class="cls-1"
            d="M386.91,124.67c-4.35,0-6.69,2.1-6.69,6v4c0,3.81,2.37,5.79,6.93,5.79,4.05,0,6.12-1.41,6.12-4.2v-.12a.44.44,0,0,0-.48-.48h-2.46c-.36,0-.48.12-.48.46v.08c0,1-.9,1.53-2.67,1.53-2.37,0-3.39-.81-3.39-2.7v-1.16h8.46c.84,0,1.23-.42,1.23-1.26V130.4C393.48,126.61,391.23,124.67,386.91,124.67Zm3.12,6.48h-6.24V130c0-1.76,1-2.69,3.09-2.69s3.15.93,3.15,2.69Z"
            transform="translate(-48.92 -48.23)"
        />
    </svg>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        en: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
