<template>
    <div class="bs-home__footer hidden-print-only">
        <div class="bs-home__footer__inner">
            <div class="bs-home__footer__inner__content">
                <h2>
                    {{ heading }}
                </h2>
                <social-media-bar
                    :locale="locale"
                ></social-media-bar>
            </div>
        </div>
    </div>
</template>

<script>
import SocialMediaBar from '@/assets/js/src/modules/navigation/_components/SocialMediaBar.vue'

export default {
    name: 'HomeFooter',
    components: {SocialMediaBar,},
    props: {
        locale: {
            type: String,
            default: 'en',
        },
        heading: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss">
    .bs-app .bs-home {
        &__footer {
            width: 100%;
            margin: 0;
            padding: 0;

            &__inner {
                position: relative;
                z-index: 3;
                margin-top: -5vw;
                background: map-deep-get($bs-color, black);


                &__content {
                    position: relative;
                    padding: 68px 24px 80px;

                    h2 {
                        margin: 0 0 40px;
                        color: map-deep-get($bs-color, white);
                        font-weight: 200;
                        font-size: 28px;
                        line-height: 28px;
                        text-align: center;
                    }

                    .bs-nav__menu-footer__social-bar {
                        justify-content: center;
                        margin: 0;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            justify-content: center;
                            margin: 0;
                        }

                        &__box {
                            max-width: 90px;

                            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                                justify-content: space-around;
                                max-width: 90px;
                            }

                            a {
                                white-space: nowrap;

                                svg {
                                    width: 33px;
                                    height: 33px;
                                }
                            }

                            i {
                                font-size: 33px;
                            }

                            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                                a:last-child {
                                    left: 50% !important;
                                    text-align: center;
                                    transform: translate(-50%, calc(100% + 40px)) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
