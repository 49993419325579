<template>
    <transition name="splash">
        <div
            v-if="loading"
            id="splash"
            class="bs-app__splash-screen"
            :class="{'bs-app__splash-screen--disabled':javascript}"
        >
            <bs-logo :en="isEnLocale"></bs-logo>
        </div>
    </transition>
</template>

<script setup>
import BsLogo from '@/assets/js/src/components/Logo.vue'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {onMounted, ref,} from "vue"

const MAX_SPLASH_SCREEN = 3000

let activePinia = getActivePinia()

const langStore = useLangStore(activePinia)
const {isEnLocale,} = storeToRefs(langStore)

defineProps({
    loading: {
        type: Boolean,
        default: true,
    },
})

const javascript = ref(true)

onMounted(() => {
    javascript.value = false
    setTimeout(() => {
        if (document.getElementById('splash')) {
            let child = document.getElementById('splash')
            child.style.display = 'none'
        }
    }, MAX_SPLASH_SCREEN)
})
</script>

<style lang="scss">
.bs-app {
    .splash-enter-from, .splash-leave-to {
        @include cache((
            opacity: 0,
        ));

        & > svg {
            @include cache((
                opacity: 0,
            ));

            transform-origin: 0;
        }
    }

    .splash-enter-active, .splash-leave-active {
        transition: opacity 1.5s ease-in-out;

        & > svg {
            transition: all .7s ease-in-out;
        }
    }

    .splash-enter-to, .splash-leave-from {
        @include cache((
            opacity: 1,
        ));

        & > svg {
            @include cache((
                opacity: 1,
            ));

            transform-origin: 0;
        }
    }

    &__splash-screen {
        position: fixed;
        inset: 0;
        z-index: 10001 !important;
        background: map-deep-get($bs-color, bs);

        & > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 640px;
            height: auto;
            transform: scale(1) translate(-50%, -50%);
            transform-origin: 0;

            @include cache((
                opacity: 1,
            ));

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                width: 480px;
            }

            &.logo__en {
                width: 368px;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    height: 51px;
                }
            }
        }
    }
}
</style>